import { useMemo, useState } from 'react';
import Select from 'react-select';

import {
  type DtoGamePack,
  ScormVersion,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { SiteBaseURL } from '../../services/public';
import { type Organization } from '../../types';
import { type GamePack } from '../../types/game';
import { RoleUtils } from '../../types/user';
import { downloadFile } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { setKnownRedirectParam } from '../../utils/redirect-to';
import { type Option } from '../common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { Loading } from '../Loading';
import { OrganizationSelect } from '../Organization/OrganizationSelect';
import { useUser } from '../UserContext';

function ScormExportPanel(props: {
  pack: GamePack | DtoGamePack;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { pack } = props;
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user);
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(
    isAdmin ? null : user.organizer?.organization ?? null
  );
  const [scormVersion, setScormVersion] = useState<ScormVersion>(
    ScormVersion.V12
  );

  const {
    call: exportScormPackage,
    state: { state: exportState },
  } = useLiveAsyncCall(async () => {
    if (!selectedOrg) {
      throw new Error('No organization selected');
    }
    const url = new SiteBaseURL();
    url.pathname = `/game-packs/${pack.id}/distribute`;
    setKnownRedirectParam(url.searchParams, 'org-id', selectedOrg.id);
    const resp = await apiService.gamePack.exportScormPackage(pack.id, {
      version: scormVersion,
      url: url.toString(),
    });
    const data = resp.data;
    downloadFile(
      URL.createObjectURL(data),
      `${pack.name} - SCORM ${scormVersion}.zip`
    );
  });

  const styles = useMemo(() => buildReactSelectStyles(), []);

  const options = useInstance(() => [
    { value: ScormVersion.V12, label: 'SCORM 1.2' },
    { value: ScormVersion.V20042ndEdition, label: 'SCORM 2004 2nd Edition' },
    { value: ScormVersion.V20043rdEdition, label: 'SCORM 2004 3rd Edition' },
    { value: ScormVersion.V20044thEdition, label: 'SCORM 2004 4th Edition' },
  ]);

  const canExportScorm = isAdmin || selectedOrg !== null;

  return (
    <div className='w-full flex flex-col items-center justify-center gap-6 py-7.5 px-10 bg-dark-gray'>
      <header className='text-center text-xl font-medium text-white'>
        Download as SCORM
      </header>
      <main className='flex flex-col items-center justify-center gap-10'>
        {canExportScorm ? (
          <>
            <p className='text-white text-base'>
              Want to run your course on an LMS or SCORM cloud? Download it as a
              SCORM package and add it directly to your LMS.
            </p>
            <p className='text-icon-gray text-sms justify-self-start'>
              The SCORM package will include everything needed for the course.
            </p>
            {isAdmin ? (
              <div>
                <p className='text-tertiary text-sms'>
                  As a Luna Park Admin, you must scope this SCORM to an
                  organization. Any new Luna Park user viewing the SCORM will be
                  registered and added to the selected organization.
                </p>
                <OrganizationSelect
                  orgId={selectedOrg?.id ?? null}
                  onChange={(org) => setSelectedOrg(org)}
                  placeholder='Select Organization'
                  className='pt-3 w-full'
                  isClearable
                  noFakeOrg
                  menuPosition='fixed'
                />
              </div>
            ) : (
              <p className='text-tertiary text-sms'>
                The SCORM package is scoped to your Organization. New learners
                will be added to your Organization after a brief registration.
                Your current organization is: {selectedOrg?.name}
              </p>
            )}

            <Select<Option<ScormVersion>, false>
              classNamePrefix='select-box-v2'
              className='w-full'
              styles={styles}
              value={options.find((o) => o.value === scormVersion) ?? null}
              options={options}
              onChange={(option) => {
                if (!option) return;
                setScormVersion(option.value);
              }}
              isSearchable={false}
              menuPosition='fixed'
            />
          </>
        ) : (
          <p className='text-white text-base'>
            You cannot export a SCORM package because you do not have an
            organization. Please contact your administrator for assistance.
          </p>
        )}
      </main>
      <footer className='flex items-center justify-center gap-2.5'>
        <button
          type='button'
          className='btn-secondary w-34 h-10 font-medium'
          onClick={props.onCancel}
        >
          Cancel
        </button>
        {canExportScorm && (
          <button
            type='button'
            className='btn-primary w-48 h-10 font-medium flex items-center justify-center gap-1'
            disabled={exportState.isRunning || (isAdmin && !selectedOrg)}
            onClick={exportScormPackage}
          >
            {exportState.isRunning && <Loading text='' />}
            <p>Download SCORM</p>
          </button>
        )}
      </footer>
    </div>
  );
}

export function useOpenScormExportModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useLiveCallback((pack: GamePack | DtoGamePack) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-160'
          innerClassName=' overflow-hidden'
          borderStyle='gray'
          onClose={p.internalOnCancel}
        >
          <ScormExportPanel
            pack={pack}
            onConfirm={p.internalOnConfirm}
            onCancel={p.internalOnCancel}
          />
        </ModalWrapper>
      ),
    });
  });
}
