import { useState } from 'react';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { SiteBaseURL } from '../../services/public';
import { RoleUtils } from '../../types/user';
import { setKnownRedirectParam } from '../../utils/redirect-to';
import { CopyLinkButtonWithPreview } from '../common/CopyButton';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { OrganizationSelect } from '../Organization/OrganizationSelect';
import { useUser } from '../UserContext';

function LMSLinkExportPanel(props: {
  packId: string;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { packId } = props;
  const user = useUser();
  const isAdmin = RoleUtils.isAdmin(user);
  const [selectedOrg, setSelectedOrg] = useState(
    isAdmin ? null : user.organizer?.organization ?? null
  );

  const url = new SiteBaseURL();
  url.pathname = `/game-packs/${packId}/distribute`;
  if (selectedOrg) {
    setKnownRedirectParam(url.searchParams, 'org-id', selectedOrg.id);
  }

  const canShareLink = isAdmin ? selectedOrg !== null : selectedOrg !== null;

  return (
    <div className='w-full flex flex-col items-center justify-center gap-6 py-7.5 px-10 bg-dark-gray'>
      <header className='text-center text-xl font-medium text-white'>
        Add to LMS via Link
      </header>
      <main className='w-full flex flex-col items-center justify-center gap-6'>
        {canShareLink || isAdmin ? (
          <>
            <p className='text-white text-base text-center'>
              Copy a direct link to this course and share it with your LMS
              administrator or embed it using an iframe or LTI integration.
            </p>
            <div className='w-full max-w-[600px]'>
              <CopyLinkButtonWithPreview
                urlToCopy={url.toString()}
                dimensionsClassName='w-full h-12'
                ctaVariant='primary'
                disabled={isAdmin && !selectedOrg}
              />
            </div>
            {isAdmin ? (
              <div className='w-full max-w-[600px]'>
                <p className='text-tertiary text-sms mb-3'>
                  As a Luna Park Admin, you must scope this link to an
                  organization. Any new Luna Park user viewing the course will
                  be registered and added to the selected organization.
                </p>
                <OrganizationSelect
                  orgId={selectedOrg?.id ?? null}
                  onChange={setSelectedOrg}
                  placeholder='Select Organization'
                  className='w-full'
                  isClearable
                  noFakeOrg
                  menuPosition='fixed'
                />
              </div>
            ) : (
              <p className='text-tertiary text-sms text-center'>
                The link is scoped to your Organization. New learners will be
                added to your Organization after a brief registration. Your
                current organization is: {selectedOrg?.name}
              </p>
            )}
          </>
        ) : (
          <p className='text-white text-base'>
            You cannot share this course because you do not have an
            organization. Please contact your administrator for assistance.
          </p>
        )}
      </main>
      <footer className='flex items-center justify-center gap-2.5'>
        <button
          type='button'
          className='btn-secondary w-34 h-10 font-medium'
          onClick={props.onCancel}
        >
          Close
        </button>
      </footer>
    </div>
  );
}

export function useOpenLMSLinkExportModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  return useLiveCallback((packId: string) => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <ModalWrapper
          containerClassName='w-160'
          innerClassName='overflow-hidden'
          borderStyle='gray'
          onClose={p.internalOnCancel}
        >
          <LMSLinkExportPanel
            packId={packId}
            onConfirm={p.internalOnConfirm}
            onCancel={p.internalOnCancel}
          />
        </ModalWrapper>
      ),
    });
  });
}
